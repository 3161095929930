import React from "react";
import "./Header.scss";

import { NavLink, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { statsDarkImg, statsResImg, statsTokenImg } from "assets/images";
import { mainPagePath } from "router/path";
import {
  avatarBgIcon,
  headerBgIcon,
  resourcesIcon,
  settingsIcon,
  userIcon,
} from "assets/svg";
import Svg from "../layout/Svg/Svg";

const Header = () => {
  const location = useLocation();
  const avatar = useSelector((state) => state.profile.tgData.avatar);

  const showLocation = location.pathname !== mainPagePath;
  return (
    <header className="header">
      <div className="container">
        <div className="header-wrapper f-center-jcsb">
          <NavLink to={mainPagePath} className="header-link f-center-center">
            <Svg id={userIcon} />
          </NavLink>
          <div className="header-user">
            <div className="header-user__avatar">
              {avatar && <img className="cover-image" src={avatar} alt="" />}
            </div>
            <div className="header-user__bg">
              <Svg id={avatarBgIcon} />
            </div>
          </div>
          <NavLink to={mainPagePath} className="header-link f-center-center">
            <Svg id={settingsIcon} />
          </NavLink>
          <div className="header-wrapper__bg">
            <Svg id={headerBgIcon} />
          </div>
        </div>
        {showLocation && (
          <div className="header-resources">
            <ul className="header-resources-list f-center-center">
              <li className="header-resources-list__item f-center">
                <div className="header-resources-list__icon">
                  <img className="cover-image" src={statsResImg} alt="" />
                </div>
                <p className="header-resources-list__text">126,90k</p>
              </li>
              <li className="header-resources-list__item f-center">
                <div className="header-resources-list__icon">
                  <img className="cover-image" src={statsDarkImg} alt="" />
                </div>
                <p className="header-resources-list__text">126,90k</p>
              </li>
              <li className="header-resources-list__item f-center">
                <div className="header-resources-list__icon">
                  <img className="cover-image" src={statsTokenImg} alt="" />
                </div>
                <p className="header-resources-list__text">126,90k</p>
              </li>
            </ul>
            <div className="header-resources__bg">
              <Svg id={resourcesIcon} />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
