import React from "react";

import "./FriendsMain.scss";
import { useState } from "react";
import { resIconImg } from "assets/images";
import { statsDarkImg } from "assets/images";
import Svg from "components/layout/Svg/Svg";
import ResourcesPopup from "components/ResourcesPopup/ResourcesPopup";
import { coinTypes } from "../../../constants/coinTypes";
import { adsgramBlocks } from "../../../constants/adsgram";

import {
  friendsCopyBtnBgIcon,
  friendsCopyIcon,
  friendsEarnBgIcon,
  friendsEarnBtnBgIcon,
  friendsShareBtnBgIcon,
  friendsShareIcon,
  friendsStatusBgIcon,
  friendsStatusFigureIcon,
} from "assets/svg";

const CollectBtn = () => {
  const [activePopup, setActivePopup] = useState(false);

  return (
    <>
      <ResourcesPopup
        activePopup={activePopup}
        onClosePopup={() => setActivePopup(false)}
        incomes={[
          { text: "750", type: coinTypes.resources },
          { text: "7", type: coinTypes.matter },
        ]}
        adIncomes={[
          { text: "x1,5", type: coinTypes.resources },
          { text: "x1,1", type: coinTypes.matter },
        ]}
        adByScript={true}
        blockId={adsgramBlocks.referralHarvest}
        onClickPrimaryBtn={() => setActivePopup(false)}
      />

      <button
        onClick={() => setActivePopup(true)}
        type="button"
        className="friends-main__earn-btn"
      >
        Собрать
        <div className="friends-main__earn-btn-bg">
          <Svg id={friendsEarnBtnBgIcon} />
        </div>
      </button>
    </>
  );
};

const FriendsMain = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const addMembersUrl = `https://t.me/testbot`;

    navigator.clipboard
      .writeText(addMembersUrl)
      .then(() => {
        setCopied(true);

        setTimeout(function () {
          setCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error("Err copy: ", err);
      });
  };

  return (
    <>
      <h2 className="friends-main__title">ДРУЗЬЯ</h2>
      <div className="friends-main__earn f-center-jcsb">
        <div className="friends-main__earn-all">
          <p className="friends-main__earn-all-title">
            Заработано за все время:
          </p>
          <ul className="friends-main__earn-res f-center">
            <li className="friends-main__earn-res-item f-center">
              <div className="friends-main__earn-res-icon">
                <img className="cover-image" src={resIconImg} alt="" />
              </div>
              <p className="friends-main__earn-res-value">126,90k</p>
            </li>
            <li className="friends-main__earn-res-item f-center">
              <div className="friends-main__earn-res-icon">
                <img className="cover-image" src={statsDarkImg} alt="" />
              </div>
              <p className="friends-main__earn-res-value">126,90k</p>
            </li>
          </ul>
        </div>
        <CollectBtn />
        <div className="friends-main__earn-bg">
          <Svg id={friendsEarnBgIcon} />
        </div>
      </div>
      <ul className="friends-main__status">
        <li className="friends-main__status-item f-center-jcsb">
          <h3 className="friends-main__status-title">Неподтвержденные</h3>
          <p className="friends-main__status-value">0</p>
          <div className="friends-main__status-bg">
            <Svg id={friendsStatusBgIcon} />
          </div>
        </li>
        <li className="friends-main__status-item f-center-jcsb">
          <h3 className="friends-main__status-title">Подтвержденные</h3>
          <p className="friends-main__status-value">12</p>
          <div className="friends-main__status-bg">
            <Svg id={friendsStatusBgIcon} />
          </div>
          <div className="friends-main__status-figure">
            <Svg id={friendsStatusFigureIcon} />
          </div>
        </li>
        <li className="friends-main__status-item f-center-jcsb">
          <h3 className="friends-main__status-title">Продвинутые</h3>
          <p className="friends-main__status-value">0</p>
          <div className="friends-main__status-bg">
            <Svg id={friendsStatusBgIcon} />
          </div>
          <div className="friends-main__status-figure">
            <Svg id={friendsStatusFigureIcon} />
          </div>
        </li>
      </ul>
      <div className="friends-main__nav f-center-jcsb">
        <button
          type="button"
          className="friends-main__nav-btn"
          onClick={copyToClipboard}
        >
          <div className="friends-main__nav-offer f-center-center">
            <Svg id={friendsCopyIcon} />
            {copied ? "Скопировано" : "Копировать ссылку"}
          </div>
          <div className="friends-main__nav-btn-bg">
            <Svg id={friendsCopyBtnBgIcon} />
          </div>
        </button>
        <button type="button" className="friends-main__nav-btn">
          <div className="friends-main__nav-offer f-center-center">
            <Svg id={friendsShareIcon} />
            Пригласить друга
          </div>
          <div className="friends-main__nav-btn-bg">
            <Svg id={friendsShareBtnBgIcon} />
          </div>
        </button>
      </div>
    </>
  );
};

export default FriendsMain;
