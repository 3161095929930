export const useAdController = (blockId) => {
  const AdController = window.Adsgram.init({ blockId });

  const onShowAd = () => {
    console.log("onShowAd");

    AdController.show()
      .then((result) => {
        console.log(result);
      })
      .catch((result) => {
        console.log("err", result);
      });
  };

  return onShowAd
};
