import TasksWrapper from "components/TasksPage/TasksWrapper/TasksWrapper";
import React from "react";

const TasksPage = () => {

  return (
    <TasksWrapper/>
  );
};

export default TasksPage;
