import React from "react";
import "./TasksTabBar.scss";
import Svg from "components/layout/Svg/Svg";
import { tasksTabBarBgIcon } from "assets/svg";

const tabs = ["Обычные задания", "Продвинутые задания", "Особые здания"];

const TasksTabBar = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tasks-tabbar f-center-jcsb">
      <div className="tasks-tabbar__wrapper f-center">
        {tabs.map((item, index) => (
          <div
          key={index}
            className={`tasks-tabbar__item f-center-center ${
              activeTab === index && "active"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="tasks-tabbar__bg">
        <Svg id={tasksTabBarBgIcon}/>
      </div>
    </div>
  );
};

export default TasksTabBar;
