import React, { useEffect, useState } from "react";
import ResourcesPopup from "components/ResourcesPopup/ResourcesPopup";
import "./MainMineBlock.scss";
import { useLocation } from "react-router-dom";
import Svg from "components/layout/Svg/Svg";
import {
  mainCollectBgIcon,
  mainMineBlockLeftTimeBgIcon,
  mainMineBlockParamsBgIcon,
  mainMineBlockRightTimeBlockIcon,
  permanentBonusBgIcon,
  resetProgressBgIcon,
  resouceBonusBgIcon,
  speedBonusBgIcon,
} from "assets/svg";
import { coinTypes } from "constants/coinTypes";
import { adsgramBlocks } from "constants/adsgram";
import { useAdController } from "../../../hooks/useAdController";

const NavBtn = ({
  icon,
  incomes,
  adIncomes,
  blockId,
  primaryBtnTxt,
  disablePrimaryBtn,
}) => {
  const [activePopup, setActivePopup] = useState(false);

  return (
    <>
      <div
        className="main-mine-block__nav"
        onClick={() => {
          setActivePopup(true);
        }}
      >
        <div className="main-mine-block__nav-btn">
          <Svg id={icon} />
        </div>
      </div>
      <ResourcesPopup
        activePopup={activePopup}
        onClosePopup={() => setActivePopup(false)}
        incomes={incomes}
        adIncomes={adIncomes}
        primaryBtnTxt={primaryBtnTxt}
        onClickPrimaryBtn={
          !disablePrimaryBtn ? () => setActivePopup(false) : null
        }
        blockId={blockId}
        adByScript
      />
    </>
  );
};

const CollectBtn = () => {
  const [activePopup, setActivePopup] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("popup=open")) {
      setActivePopup(true);
    }
  }, [location]);

  return (
    <>
      <div
        className="main-mine-block__btn"
        onClick={() => setActivePopup(true)}
      >
        <span className="main-mine-block__btn-text">Собрать</span>
        <div className="main-mine-block__btn-bg">
          <Svg id={mainCollectBgIcon} />
        </div>
      </div>
      <ResourcesPopup
        activePopup={activePopup}
        onClosePopup={() => setActivePopup(false)}
        incomes={[{ text: "750", type: coinTypes.resources }]}
        adIncomes={[{ text: "x2", type: coinTypes.resources }]}
        blockId={adsgramBlocks.resourceHarvest}
        onClickPrimaryBtn={() => setActivePopup(false)}
      />
    </>
  );
};

const MainMineBlock = () => {
  const onShowAd = useAdController(adsgramBlocks.miningSpeedBonus);

  return (
    <>
      <div className="main-mine-block">
        <div className="main-mine-block__params">
          <div className="main-mine-block__params-head f-center-jcsb">
            <p className="main-mine-block__params-point">Время добычи</p>
            <p className="main-mine-block__params-point">Добыто ресурсов</p>
          </div>
          <div className="main-mine-block__params-wrap f-center-jcsb">
            <p className="main-mine-block__params-value main-mine-block__params-value_time f-center-center">
              16 : 10 : 20
            </p>
            <p className="main-mine-block__params-value f-center-center">
              37,543
            </p>
          </div>
          <div className="main-mine-block__params-bg">
            <Svg id={mainMineBlockParamsBgIcon} />
          </div>
        </div>
        <div className="main-mine-block__control">
          <div className="main-mine-block__time f-jcsb">
            <div className="main-mine-block__time-item">
              <span className="main-mine-block__time-value">16 : 10 : 20</span>
              <div className="main-mine-block__time-bg">
                <Svg id={mainMineBlockLeftTimeBgIcon} />
              </div>
            </div>
            <div className="main-mine-block__time-item">
              <span className="main-mine-block__time-value">16 : 10 : 20</span>
              <div className="main-mine-block__time-bg">
                <Svg id={mainMineBlockRightTimeBlockIcon} />
              </div>
            </div>
          </div>
          <div className="main-mine-block__control-inner f-jcsb">
            <NavBtn
              icon={permanentBonusBgIcon}
              incomes={[{ text: "+0,5%" }]}
              primaryBtnTxt={"500"}
              disablePrimaryBtn
              blockId={adsgramBlocks.permanentBonus}
            />
            <div className="main-mine-block__nav" onClick={onShowAd}>
              <div className="main-mine-block__nav-btn">
                <Svg id={speedBonusBgIcon} />
              </div>
            </div>
            <NavBtn
              icon={resouceBonusBgIcon}
              incomes={[{ text: "5/час", type: coinTypes.resources }]}
              primaryBtnTxt={"Закрыть"}
              blockId={adsgramBlocks.resourcesBonus}
            />
            <NavBtn
              icon={resetProgressBgIcon}
              incomes={[{ text: "5", type: coinTypes.matter }]}
              adIncomes={[{ text: "х1,25", type: coinTypes.matter }]}
              blockId={adsgramBlocks.resetProgress}
            />
            <CollectBtn />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainMineBlock;
