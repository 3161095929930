import React from "react";
import Svg from "../layout/Svg/Svg";
import { resourcesPopupCollectBtnBgIcon } from "../../assets/svg";

import "./AdsBlockedDesk.scss";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";

const AdsBlockedDesk = () => {
  return (
    <TransitionProvider
      inProp={true}
      style={TransitionStyleTypes.opacity}
      className="ads-block-desk"
    >
      <h3 className="ads-block-desk__text">
        Для продолжения игры отключите, пожалуйста, блокировщик рекламы или
        купите премиум аккаунт
      </h3>
      <button
        type="button"
        className="ads-block-desk__btn"
        onClick={() => window.location.reload()}
      >
        Обновить
        <div className="ads-block-desk__btn-bg">
          <Svg id={resourcesPopupCollectBtnBgIcon} />
        </div>
      </button>{" "}
    </TransitionProvider>
  );
};

export default AdsBlockedDesk;
