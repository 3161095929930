import React from "react";

import "./FriendsWrapper.scss";
import FriendsMain from "../FriendsMain/FriendsMain";
import FriendsRewards from "../FriendsRewards/FriendsRewards";

const FriendsWrapper = () => {
  return (
    <div className="container">
      <div className="friends-wrapper space-control">
        <FriendsMain />
        <FriendsRewards />
      </div>
    </div>
  );
};

export default FriendsWrapper;
