import BuildsPage from "pages/BuildsPage";
import FriendsPage from "pages/FriendsPage/FriendsPage";
import MainPage from "pages/MainPage";
import TasksPage from "pages/TasksPage";
import { Navigate } from "react-router-dom";

export const mainPagePath = "/";
export const buildsPagePath = "/builds";
export const achievmentsPagePath = "/achievments";
export const friendsPagePath = "/friends";
export const tasksPagePath = "/tasks";

export const routes = [
  {
    path: mainPagePath,
    component: <MainPage />,
  },
  {
    path: buildsPagePath,
    component: <BuildsPage />,
  },
  {
    path: friendsPagePath,
    component: <FriendsPage />,
  },
  {
    path: tasksPagePath,
    component: <TasksPage />,
  },
  {
    path: "*",
    component: <Navigate to={mainPagePath}/>,
  },
];
