import { generateSignature } from "services/crypto";

const API_KEY =
  "700516c8d9fb0ed9547a48bafa05fd8dc8463818dcc5b5831a2dc1c3040e9e3b";
export const baseUrl = "https://krasnoperovg-dystopia-app-a95c.twc1.net/";
export const baseConfig = {};

// ENDPOINTS

export const fetchRequest = async (fetchUrl, tgId, method, body) => {
  const config = baseConfig;
  const isAuth = !!tgId;
  let url = `${baseUrl}${fetchUrl}`;

  if (isAuth) {
    // eslint-disable-next-line no-undef
    const { signatureHex, timestamp } = generateSignature(API_KEY,tgId);

    // request bildings.research_institute.level&timestamp
    url += `?id_tgrm=${tgId}&timestamp=${timestamp}&signature=${signatureHex}`;
  }

  console.log({ url });

  const response = await fetch(url, {
    method: method,
    body: body && JSON.stringify(body),
    ...config,
  });

  // eslint-disable-next-line no-undef
  const resData = await response.json();

  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw { message: resData, status: response.status };
  }
  return resData;
};
