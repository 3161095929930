// src/redux/slices/userSlice.ts
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  tgData: {
    tgId: "",
    avatar: "",
  }
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getTgUser(state, {payload}) {
      state.tgData = payload;
    },
  },
});

export const { getTgUser } = profileSlice.actions;

export default profileSlice.reducer;
