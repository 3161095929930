import React, { useEffect, useRef } from "react";
import "./ResourcesPopup.scss";
import Svg from "components/layout/Svg/Svg";
import {
  resourcesPopupBottomIcon,
  resourcesPopupCollectBtnBgIcon,
  resourcesPopupLeftFigureIcon,
  resourcesPopupMoreIcon,
  resourcesPopupRightFigureIcon,
  resourcesPopupValueBgIcon,
  resourcesPopupViewAdBtnBgIcon,
} from "assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { statsImages } from "constants/stats";
import NewPortalProvider from "providers/NewPortalProvider";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { useAdController } from "hooks/useAdController";

const ResourcesPopup = ({
  activePopup,
  onClosePopup,
  incomes,
  adIncomes,
  title,
  primaryBtnTxt,
  onClickPrimaryBtn,
  adbtnClass,
  adBtnTxt,
  onClickAdBtn,
  adByScript,
  blockId,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const onShowAd = useAdController(blockId);
  const frameContainerRef = useRef();

  useEffect(() => {
    if (adByScript && activePopup) {
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.innerHTML = `
      atOptions = {
        'key' : 'b8cf70b3ea2d5849d9d0a40c0c9341c7',
        'format' : 'iframe',
        'height' : 250,
        'width' : 300,
        'params' : {}
      };
    `;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src =
        "//www.highperformanceformat.com/b8cf70b3ea2d5849d9d0a40c0c9341c7/invoke.js";
    
      if (frameContainerRef.current) {
        frameContainerRef.current?.appendChild(script1);
        frameContainerRef.current?.appendChild(script2);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup]);

  const onClose = () => {
    onClosePopup();
    if (location.search.includes("popup=open")) {
      navigate(location.pathname);
    }
  };

  return (
    <NewPortalProvider>
      <TransitionProvider
        inProp={activePopup}
        style={TransitionStyleTypes.opacity}
        className={`resources-popup`}
      >
        <div
          className="resources-popup__overlay"
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <div className="resources-popup__body">
            <h2 className="resources-popup__title">
              {title || "Доступно к сбору"}
            </h2>
            <div className="resources-popup__wrap f-jcsb">
              <div className="resources-popup__figure">
                <Svg id={resourcesPopupLeftFigureIcon} />
              </div>
              <div className="resources-popup__value f-center-center">
                {incomes.map(({ text, type }, index) => (
                  <div
                    key={index}
                    className="resources-popup__value-content f-center"
                  >
                    {type in statsImages ? (
                      <img src={statsImages[type]} alt="" />
                    ) : (
                      ""
                    )}
                    {text}
                  </div>
                ))}
                <div className="resources-popup__value-bg">
                  <Svg id={resourcesPopupValueBgIcon} />
                </div>
              </div>
              <div className="resources-popup__figure">
                <Svg id={resourcesPopupRightFigureIcon} />
              </div>
            </div>
            <div className="resources-popup__more">
              <Svg id={resourcesPopupMoreIcon} />
            </div>
            <div className="resources-popup__nav f-center-jcsb">
              <button
                type="button"
                className="resources-popup__btn"
                disabled={!onClickPrimaryBtn}
                onClick={() => {
                  onClickPrimaryBtn();
                  onClose();
                }}
              >
                {primaryBtnTxt || "Собрать"}
                <div className="resources-popup__btn-bg">
                  <Svg id={resourcesPopupCollectBtnBgIcon} />
                </div>
              </button>
              <p className="resources-popup__and">или</p>
              <button
                onClick={() => {
                  if (onClickAdBtn) onClickAdBtn();
                  onShowAd();
                }}
                type="button"
                className={`resources-popup__btn f-center-center ${
                  adbtnClass || ""
                }`}
              >
                {adBtnTxt || (
                  <>
                    Просмотреть <br />
                    рекламу
                  </>
                )}
                <div className="resources-popup__points">
                  {adIncomes &&
                    adIncomes.map(({ text, type }, index) => (
                      <div
                        className="resources-popup__points-item f-center"
                        key={index}
                      >
                        {type in statsImages && (
                          <img src={statsImages[type]} alt="" />
                        )}
                        {text}
                      </div>
                    ))}
                </div>
                <div className="resources-popup__btn-bg">
                  <Svg id={resourcesPopupViewAdBtnBgIcon} />
                </div>
              </button>
            </div>

            <div className="resources-popup__inner">
              <div className="resources-popup__banner">
                <div
                  id="frame"
                  ref={frameContainerRef}
                  style={{ width: "300px", height: "auto" }}
                >
                  {!adByScript && (
                    <>
                      <iframe
                        title="banner"
                        data-aa="2369669"
                        src="//ad.a-ads.com/2369669?size=300x250"
                        style={{
                          width: "300px",
                          height: "250px",
                          border: "0px",
                          padding: "0",
                          overflow: "hidden",
                          backgroundColor: "transparent",
                        }}
                      ></iframe>
                      <a
                        style={{
                          display: "block",
                          textAlign: "right",
                          fontSize: "12px",
                        }}
                        id="preview-link"
                        href="https://aads.com/campaigns/new/?source_id=2369669&source_type=ad_unit&partner=2369669"
                      >
                        Advertise here
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="resources-popup__bottom">
              <Svg id={resourcesPopupBottomIcon} />
            </div>
          </div>
        </div>
      </TransitionProvider>
    </NewPortalProvider>
  );
};

export default ResourcesPopup;
