import React from "react";
import { statsTokenImg, statsDarkImg, statsResImg } from "assets/images";
import Svg from "components/layout/Svg/Svg";
import { mainStatItemBgIcon, mainStatsBgIcon } from "assets/svg";

import "./MainStats.scss";

const stats = [
  {
    name: "Ресурсы",
    icon: statsResImg,
  },
  {
    name: "Темная материя",
    icon: statsDarkImg,
  },
  {
    name: "жетоны",
    icon: statsTokenImg,
  },
];

const MainStats = () => {
  return (
    <div className="main-stats f-jcsb">
      {stats.map(({ name, icon }, index) => (
        <div className="main-stats__item" key={index}>
          <div className="main-stats__offer f-center">
            <div className="main-stats__icon">
              <img className="cover-image" src={icon} alt={name} />
            </div>
            <p className="main-stats__title">{name}</p>
          </div>
          <p className="main-stats__value">126,90k</p>
          <div className="main-stats__item-bg">
            <Svg id={mainStatItemBgIcon} />
          </div>
        </div>
      ))}

      <div className="main-stats__bg">
        <Svg id={mainStatsBgIcon} />
        
      </div>
    </div>
  );
};

export default MainStats;
