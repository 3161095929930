export const mobMenuTopIcon = "mobMenuTop"
export const menuBgDefaultIcon = "menuBgDefault"
export const menuBgActiveIcon = "menuBgActive"
export const mobileMenuBorderIcon = "mobileMenuBorder"
export const menuAchievmentsIcon = "menuAchievments"
export const menuBuildsIcon = "menuBuilds"
export const menuMainIcon = "menuMain"
export const menuTasksIcon = "menuTasks"
export const menuFriendsIcon = "menuFriends"
export const menuBottomIcon = "menuBottom"
export const userIcon = "user"
export const avatarBgIcon = "avatarBg"
export const headerBgIcon = "headerBg"
export const settingsIcon = "settings"
export const resourcesIcon = "resources"
export const mainStatItemBgIcon = "mainStatItemBg"
export const mainStatsBgIcon = "mainStatsBg"
export const buildsModeSwithBgIcon = "buildsModeSwithBg"
export const buildsTabBarBgIcon = "buildsTabBarBg"
export const buildsUpgradeBgIcon = "buildsUpgradeBg"
export const buildsProgressCircleIcon = "buildsProgressCircle"
export const tasksTabBarBgIcon = "tasksTabBarBg"
export const taskListRewardBgIcon = "taskListRewardBg"
export const taskSubscribeBtnBgIcon = "taskSubscribeBtnBg"
export const taskGetBtnBgIcon = "taskGetBtnBg"
export const taskDailyBgIcon = "taskDailyBg"
export const friendsEarnBtnBgIcon = "friendsEarnBtnBg"
export const friendsEarnBgIcon = "friendsEarnBg"
export const friendsStatusBgIcon = "friendsStatusBg"
export const friendsStatusFigureIcon = "friendsStatusFigure"
export const friendsCopyBtnBgIcon = "friendsCopyBtnBg"
export const friendsShareBtnBgIcon = "friendsShareBtnBg"
export const friendsCopyIcon = "friendsCopy"
export const friendsShareIcon = "friendsShare"
export const resourcesPopupLeftFigureIcon = "resourcesPopupLeftFigure"
export const resourcesPopupRightFigureIcon = "resourcesPopupRightFigure"
export const resourcesPopupValueBgIcon = "resourcesPopupValueBg"
export const resourcesPopupMoreIcon = "resourcesPopupMore"
export const resourcesPopupCollectBtnBgIcon = "resourcesPopupCollectBtnBg"
export const resourcesPopupViewAdBtnBgIcon = "resourcesPopupViewAdBtnBg"
export const resourcesPopupBottomIcon = "resourcesPopupBottom"
export const mainMineBlockParamsBgIcon = "mainMineBlockParamsBg"
export const mainMineBlockLeftTimeBgIcon = "mainMineBlockLeftTimeBg"
export const mainMineBlockRightTimeBlockIcon = "mainMineBlockRightTimeBlock"
export const permanentBonusBgIcon = "permanentBonusBg"
export const speedBonusBgIcon = "speedBonusBg"
export const resouceBonusBgIcon = "resouceBonusBg"
export const resetProgressBgIcon = "resetProgressBg"
export const mainCollectBgIcon = "mainCollectBg"