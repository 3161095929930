import { fetchRequest } from "../tools/fetchTools";

const getDataUrl = "account/";

export const getData = () => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const data = await fetchRequest(getDataUrl, tgId);
    console.log({ data });

    return data;
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};
