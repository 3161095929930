import React from "react";
import MainStats from "../MainStats/MainStats";
import MainMineBlock from "../MainMineBlock/MainMineBlock";

const MainWrapper = () => {
  return (
    <section className="container">
      <div className="main-wrapper space-control">
        <MainStats />
        <MainMineBlock />
      </div>
    </section>
  );
};

export default MainWrapper;
