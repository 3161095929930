import React from "react";

import "./FriendsRewards.scss";
import { getResIconImg } from "assets/images";

const FriendsRewards = () => {
  return (
    <div className="friends-rewards">
      <h3 className="friends-rewards__title">Получай уже сейчас</h3>
      <div className="friends-rewards__list f-jcsb">
        <div className="friends-rewards__item f-center-center">
          <div className="friends-rewards__value">+50</div>
          <div className="friends-rewards__icon">
            <img src={getResIconImg} alt="" />
          </div>
          <p className="friends-rewards__text">за регистрацию</p>
        </div>
        <div className="friends-rewards__item">
          <div className="friends-rewards__value">0,1%</div>
          <p className="friends-rewards__text">
            от каждого сбора ресурсов рефералов
          </p>
        </div>
        <div className="friends-rewards__item">
          <div className="friends-rewards__value">1%</div>
          <p className="friends-rewards__text">от каждого сброса прогресса</p>
        </div>
      </div>
    </div>
  );
};

export default FriendsRewards;
