import React from 'react'
import {svgSprite} from "../../../assets/svgSprite";


const Svg = ({className, id}) => {
    return (
        <>{svgSprite(className)[id]}</>
    )
}

export default Svg