import React from "react";
import "./TasksList.scss";
import { statsImages } from "constants/stats";
import Svg from "components/layout/Svg/Svg";
import { resIconImg } from "assets/images";
import {
  taskDailyBgIcon,
  taskGetBtnBgIcon,
  taskListRewardBgIcon,
  taskSubscribeBtnBgIcon,
} from "../../../assets/svg";

const tasks = {
  regular: [
    {
      title: "Награда за ежедневный вход",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      income: {
        type: "resource",
        amount: 126,
      },
      link: "",
      completed: true,
      time: 0,
    },
    {
      title: "Награда за ежедневный вход",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      income: {
        type: "resource",
        amount: 126,
      },
      link: "",
      completed: false,
      time: 0,
    },
    {
      title: "Награда за ежедневный вход",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      income: {
        type: "resource",
        amount: 126,
      },
      link: "",
      completed: false,
      time: Date.now(),
    },
    {
      title: "Награда за ежедневный вход",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      income: {
        type: "resource",
        amount: 126,
      },
      link: "",
      completed: false,
      time: 0,
    },
    {
      title: "Награда за ежедневный вход",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      income: {
        type: "resource",
        amount: 126,
      },
      link: "",
      completed: false,
      time: 0,
    },
    {
      title: "Награда за ежедневный вход",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      income: {
        type: "resource",
        amount: 126,
      },
      link: "",
      completed: false,
      time: 0,
    },
  ],
  advanced: [
    {
      title: "Потраченные ресурсы",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      level: 1,
      progress: [127, 150],
    },
    {
      title: "Потраченные ресурсы",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      level: 1,
      progress: [127, 150],
    },
    {
      title: "Потраченные ресурсы",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      level: 1,
      progress: [127, 150],
    },
    {
      title: "Потраченные ресурсы",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      level: 1,
      progress: [127, 150],
    },
    {
      title: "Потраченные ресурсы",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
      level: 1,
      progress: [127, 150],
    },
  ],
  special: []
};

const RegularTaskItem = ({
  title,
  description,
  income,
  link,
  completed,
  time,
}) => (
  <div className="tasks-list__card">
    <div className="tasks-list__main">
      <h3 className="tasks-list__title">{title}</h3>
      <p className="tasks-list__text">{description}</p>
      <div className="tasks-list__offer f-center-jcsb">
        <div className="tasks-list__reward f-center-center">
          <img src={statsImages[income.type]} alt="" />
          {income.value}
          <div className="tasks-list__reward-bg">
            <Svg id={taskListRewardBgIcon} />
          </div>
        </div>
        <div className="tasks-list__btns f-center">
          <button type="button" className="tasks-list__btn">
            Подписаться
            <div className="tasks-list__btn-bg">
              <Svg id={taskSubscribeBtnBgIcon} />
            </div>
          </button>
          <button type="button" className="tasks-list__btn">
            Получить
            <div className="tasks-list__btn-bg">
              <Svg id={taskGetBtnBgIcon} />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
);

const AdvancedTaskItem = ({ title, description, level, progress }) => (
  <div className="tasks-list__card">
    <div className="tasks-list__main">
      <div className="tasks-list__head f-center-jcsb">
        <h3 className="tasks-list__title">{title}</h3>
        <p className="tasks-list__lvl">
          <span>{level}</span> ур
        </p>
      </div>
      <p className="tasks-list__text">{description}</p>
      <div className="tasks-list__offer f-center-jcsb">
        <div className="tasks-list__tag">
          Транжира
          <div className="tasks-list__tag-bg">
            <Svg id={taskListRewardBgIcon} />
          </div>
        </div>
        <div className="tasks-list__btns f-center">
          <p className="tasks-list__count">
            {progress[0]} <span>/ {progress[10]}</span>
          </p>
          <button type="button" className="tasks-list__btn">
            Получить
            <div className="tasks-list__btn-bg">
              <Svg id={taskGetBtnBgIcon} />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
);

const TasksList = ({ activeTab }) => {
  return (
    <div className="tasks-list">
      <div className={`tasks-list__content-item`}>
        <ul>
          {!activeTab && (
            <>
              <li className="tasks-list__item">
                <div className="tasks-list__card">
                  <div className="tasks-list__daily f-center-jcsb">
                    <h3 className="tasks-list__title">
                      Награда за ежедневный вход
                    </h3>
                    <div className="tasks-list__daily-date">
                      <p className="tasks-list__daily-title">День</p>
                      <p className="tasks-list__daily-value">1</p>
                      <div className="tasks-list__daily-bg">
                        <Svg id={taskDailyBgIcon} />
                      </div>
                    </div>
                    <div className="tasks-list__daily-info">
                      <div className="tasks-list__reward f-center-center">
                        <img src={resIconImg} alt="" />
                        126
                        <div className="tasks-list__reward-bg">
                          <Svg id={taskListRewardBgIcon} />
                        </div>
                      </div>
                      <button type="button" className="tasks-list__btn">
                        Собрать
                        <div className="tasks-list__btn-bg">
                          <Svg id={taskGetBtnBgIcon} />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li className="tasks-list__item">
                <div className="tasks-list__card">
                  <div className="tasks-list__banner"></div>
                </div>
              </li>
            </>
          )}
          {Object.values(tasks)[activeTab].map((task, index) => {
            let content = "";

            switch (activeTab) {
              case 0: {
                content = <RegularTaskItem {...task} />;
                break;
              }
              case 1: {
                content = <AdvancedTaskItem {...task} />;
                break;
              }
              default:
                content = "";
            }

            return (
              <li className="tasks-list__item" key={index}>
                {content}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TasksList;
