import React from "react";
import "./BuildsWrapper.scss";
import BuildsTabBar from "../BuildsTabBar/BuildsTabBar";
import BuildsList from "../BuildsList/BuildsList";

const BuildsWrapper = () => {
  return (
    <section className="container">
      <div className="builds-wrapper space-control">
        <BuildsTabBar/>
        <BuildsList/>
      </div>
    </section>
  );
};

export default BuildsWrapper;
