import React from "react";
import { useState } from "react";
import Svg from "components/layout/Svg/Svg";
import { buildsModeSwithBgIcon, buildsTabBarBgIcon } from "assets/svg";
import "./BuildsTabBar.scss";

const tabs = ["Здания", "Улучшения города", "Особые здания"];

const BuildsTabBar = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="builds-tabBar f-center-jcsb">
      <div className="builds-tabBar__wrapper f-center">
        {tabs.map((item, index) => (
          <div
            key={index}
            className={`builds-tabBar__item f-center-center ${
              activeTab === index && "active"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="builds-tabBar__change f-center-center">
        <p className="builds-tabBar__change-text">
          Смена
          <br />
          режима
        </p>
        <div className="builds-tabBar__change-bg">
          <Svg id={buildsModeSwithBgIcon} />
        </div>
      </div>
      <div className="builds-tabBar__bg">
        <Svg id={buildsTabBarBgIcon} />
      </div>
    </div>
  );
};

export default BuildsTabBar;
