import React, { useState } from "react";
import "./TasksWrapper.scss";
import TasksTabBar from "../TasksTabBar/TasksTabBar";
import TasksList from "../TasksList/TasksList";

const TasksWrapper = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <section className="container">
      <div className="tasks-inner space-control">
        <TasksTabBar activeTab={activeTab} setActiveTab={setActiveTab} />
        <TasksList activeTab={activeTab} />
      </div>
    </section>
  );
};

export default TasksWrapper;
