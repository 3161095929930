import React, { useState } from "react";
import "./BuildsList.scss";
import {
  buildsImg,
  buildsItemBgImg,
  buildsAccentImg,
  statsDarkImg,
  statsResImg,
  statsTokenImg,
  resIconImg,
} from "assets/images";
import Svg from "components/layout/Svg/Svg";
import { buildsProgressCircleIcon, buildsUpgradeBgIcon } from "assets/svg";
import { statsImages } from "../../../constants/stats";
import ResourcesPopup from "components/ResourcesPopup/ResourcesPopup";
import { coinTypes } from "../../../constants/coinTypes";
import { adsgramBlocks } from '../../../constants/adsgram';

const incomeTypes = {
  resources_per_hour: {
    statImg: resIconImg,
  },
  matter_per_hour: {
    statImg: statsDarkImg,
  },
  tokens_per_hour: {
    statImg: statsTokenImg,
  },
  bonus: {
    statImg: statsResImg,
  },
};

const builds = [
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour", // | "matter_per_hour" | "tokens_per_hour" | "bonus"
      value: 15,
    },
    price: {
      type: "resources", // | "matter" | "token"
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour",
      value: 15,
    },
    price: {
      type: "resources",
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour",
      value: 15,
    },
    price: {
      type: "resources",
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour",
      value: 15,
    },
    price: {
      type: "resources",
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour",
      value: 15,
    },
    price: {
      type: "resources",
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour",
      value: 15,
    },
    price: {
      type: "resources",
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour",
      value: 15,
    },
    price: {
      type: "resources",
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
  {
    name: "Исследовательские институты",
    image: buildsImg,
    type: "building",
    income: {
      type: "resources_per_hour",
      value: 15,
    },
    price: {
      type: "resources",
      value: 20,
    },
    completedUpgradeLevel: 6,
  },
];

const BuildsList = () => {
  const [activeUpgradePopupIndex, setActiveUpgradePopupIndex] = useState(0);
  return (
    <ul className="builds-list">
      {builds.map(
        (
          { name, image, type, income, price, completedUpgradeLevel },
          index
        ) => (
          <li className="builds-list__item" key={index}>
            <div className="builds-list__card f-center-jcsb">
              <div className="builds-list__image">
                <img className="cover-image" src={image} alt={name} />
              </div>
              <div className="builds-list__content">
                <h2 className="builds-list__title">{name}</h2>
                <div className="builds-list__progress f-jcsb">
                  {Array.from({ length: 6 }).map((_, upgradeIndex) => (
                    <div
                      className="builds-list__progress-item"
                      key={upgradeIndex}
                      onClick={() => setActiveUpgradePopupIndex(upgradeIndex + 1)}
                    >
                      <div className="builds-list__progress-card">
                        <p className="builds-list__progress-title">апргрейд</p>
                        <p className="builds-list__progress-value">
                          {upgradeIndex + 1}
                        </p>
                      </div>
                      <div className="builds-list__progress-bg">
                        <img
                          src={
                            completedUpgradeLevel === upgradeIndex + 1
                              ? buildsAccentImg
                              : buildsItemBgImg
                          }
                          alt="upgrade"
                        />
                      </div>
                    </div>
                  ))}

                  <div className="builds-list__progress-circle builds-list__progress-circle_first">
                    <Svg id={buildsProgressCircleIcon} />
                  </div>
                  <div className="builds-list__progress-circle builds-list__progress-circle_second">
                    <Svg id={buildsProgressCircleIcon} />
                  </div>
                </div>
              </div>
              <div className="builds-list__token">
                <div className="builds-list__earn f-center-center">
                  {income.value}
                  <img src={incomeTypes[income.type].statImg} alt="" />
                  /час
                </div>
                <button
                  type="button"
                  className="builds-list__upgrade f-center-center"
                >
                  <div className="builds-list__upgrade-content">
                    <p className="builds-list__upgrade-lvl">
                      <span>+1</span> уровень
                    </p>
                    <p className="builds-list__upgrade-title">Улучшить</p>
                    <p className="builds-list__upgrade-price f-center-center">
                      за <span>{price.value}</span>{" "}
                      <img src={statsImages[price.type]} alt="" />
                    </p>
                  </div>
                  <div className="builds-list__upgrade-bg">
                    <Svg id={buildsUpgradeBgIcon} />
                  </div>
                </button>
              </div>
            </div>
          </li>
        )
      )}
      <ResourcesPopup
        activePopup={!!activeUpgradePopupIndex}
        onClosePopup={() => setActiveUpgradePopupIndex(0)}
        incomes={[{ text: `апргрейд ${activeUpgradePopupIndex}` }]}
        adIncomes={[{ text: "Бесплатно", type: coinTypes.resources }]}
        blockId={adsgramBlocks.buildingUpgrade}
        adBtnTxt={`Просмотреть рекламу`}
        adbtnClass={"builds-list__adBtn"}
        onClickPrimaryBtn={() => setActiveUpgradePopupIndex(0)}
      />
    </ul>
  );
};

export default BuildsList;
